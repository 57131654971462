<template>
  <div class="page">
    <el-form size="small" :inline="true" class="query-form" ref="searchForm" :model="searchForm">
      <el-form-item prop="title">
        <el-input size="small" v-model="searchForm.title" maxlength="20" placeholder="请输入藏品名称或编号"
                  clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="search()" size="small" icon="el-icon-search">查询</el-button>
        <el-button @click="resetSearch()" size="small" icon="el-icon-refresh-right">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="bg-white top">
      <el-row>
        <el-col :span="18">
          <AdvancedQuery ref="advancedQuery" :moduleId="moduleId" @getDataList="atlGetDataList"></AdvancedQuery>
        </el-col>
        <el-col :span="6" class="text_right">
          <el-button-group class="m_r1">
            <el-button size="small" plain icon="el-icon-refresh-left" @click="refreshAll"></el-button>
            <el-button size="small" plain icon="el-icon-setting" @click="setTb"></el-button>
          </el-button-group>
        </el-col>
      </el-row>
      <el-table
          :data="dataList"
          v-loading="loading"
          size="small"
          height="calc(100% - 80px)"
          @selection-change="selectionChangeHandle"
          class="table" ref="multipleTable">
        <el-table-column prop="thumb_path" width="110" label="缩略图">
          <template slot-scope="scope">
            <el-image style="width: 100px; height: 100px" :src="scope.row.thumb_path" fit="contain">
              <el-image slot="error" fit="contain" style="width: 100px; height: 100px"
                        :src="require('@/assets/img/thumbnail.png')"></el-image>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column v-for="(item,index) in columnList" :key="index"
                         v-if="item.fieldEname != 'thumb_path'"
                         :prop="item.fieldEname" min-width="160"
                         show-overflow-tooltip sortable
                         :label="item.fieldName">
        </el-table-column>
        <el-table-column
            fixed="right"
            width="100"
            label="操作">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" v-show="hasPermission('collection:list:view')"
                       plain @click="view('view', scope.row)">查看
            </el-button>
            <!--<el-button size="mini" type="primary" plain @click="rt(scope.row)">富文本</el-button>-->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageNo"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :total="total"
          background
          layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <!--列表设置拖拽排序弹窗-->
    <DraggablePop :moduleId="moduleId" :setShow="setShow" @getTbList="getTbList"></DraggablePop>
    <!--查看-->
    <CollectionForm ref="collectionForm"></CollectionForm>
    <!--富文本-->
    <RichText ref="richText"></RichText>
  </div>
</template>

<script>
import DraggablePop from '@/components/draggable/draggablePop2' // 导入排序弹窗
import AdvancedQuery from '@/components/advancedQuery/advancedQuery' // 导入高级筛选弹窗
import CollectionForm from './collectionForm' // 查看弹窗
import RichText from './richText' // 查看弹窗
export default {
  components: {DraggablePop, AdvancedQuery, CollectionForm, RichText},
  data() {
    return {
      searchForm: {
        title: "",
      },
      dataList: [],
      /*originalId: '',*/
      pageNo: 0,
      pageSize: 10,
      total: 0,
      loading: false,
      dataListSelections: [],
      columnList: [],
      onScene: false, // 是否正在使用场景
      dataListQuery: [], // 临时储存场景
      moduleId: '946049935122366464',   // 当前请求拖拽排序数据id
      setShow: false, // 是否显示列表设置拖拽排序弹窗
    }
  },
  mounted() {
    this.getTableByResourceName()
    this.refreshList();
  },
  watch: {
    searchDates() {
      if (this.searchDates) {
        this.searchForm.beginDate = this.searchDates[0]
        this.searchForm.endDate = this.searchDates[1]
      } else {
        this.searchForm.beginDate = ''
        this.searchForm.endDate = ''
      }
    },
    columnList() {
      this.$nextTick(() => {
        this.$refs.multipleTable.doLayout();
      })
    }
  },
  methods: {
    getTableByResourceName() {
      this.$axios(this.api.original.metaDataDirectoryList, {
        resourceName: '藏品资源',
      }, 'get').then(res => {
        if (res.status) {
          this.moduleId = res.data.records[0].id;
        }
      })
    },
    // 刷新按钮回到全部列表
    refreshAll() {
      this.onScene = false
      this.dataListQuery.queryConditions = []
      this.searchForm.title = ''
      this.pageNo = 0
      this.refreshList()
    },
    // 获取数据列表
    refreshList() {
      this.loading = true
      if (this.onScene || (this.dataListQuery.queryConditions != null && this.dataListQuery.queryConditions.length > 0)) {
        this.getDataList(this.dataListQuery)
        this.loading = false
        return
      }
      this.dataListQuery.sceneId = ''
      this.$axios(this.api.rcPage.resourceCollectionList, {
        'current': this.pageNo,
        'size': this.pageSize,
        'type': this.searchForm.type,
        'searchNumOrName': this.searchForm.title,
        'originalId': this.moduleId
      }, 'get').then(data => {
        this.dataList = data.data.records
        this.total = parseInt(data.data.total);
        setTimeout(() => {
          this.$nextTick(() => {
            this.$refs.multipleTable.doLayout();
          })
        }, 100)
        this.loading = false
      })
    },
    // 获取显示字段
    getColumnList() {
      var that = this;
      this.columnList = []
      that.$axios(this.api.sys.getByOriginalId + this.moduleId, '', "get").then((res) => {
        if (res.status) {
          res.data.map(item => {
            if (item.logicType == 0) {
              this.columnList.push(item)
            }
          })
        }
      })
    },
    // 查看
    view(method, row) {
      this.$refs.collectionForm.init(method, row);
    },

    rt(row) {
      this.$refs.richText.init(JSON.parse(JSON.stringify(row)));
    },
    // 查询
    getFileList() {
      this.loading = true
      this.$axios(this.api.common.culturalRelicsList, {}, 'get').then(data => {
        if (data && data.status) {
        }
      })
    },
    // 高级查询
    atlGetDataList(data) {
      this.pageNo = 0
      this.getDataList(data)
    },
    // 获取高级查询条件
    getDataList(data) {
      if (data != null && data.sceneId != null && data.sceneId != '') {
        this.onScene = true
      } else {
        this.onScene = false
      }
      if (data.clearCurrent != null && data.clearCurrent == true) {
        this.pageNo = 0
      }
      this.dataListQuery = data
      this.searchTpye = 0
      data.size = this.pageSize
      data.current = this.pageNo
      data.searchNumOrName = this.searchForm.title
      this.$axios(this.api.rcPage.collectionQueryResource, JSON.stringify(data), 'post').then(res => {
        if (res && res.status) {
          this.dataList = res.data.records
          this.total = parseInt(res.data.total);
        }
      })
    },
    // 设置
    setTb() {
      this.setShow = true;
    },
    // 接收子组件（排序弹窗）传过来的值
    getTbList(data, state) {
      this.columnList = []
      this.columnList = data;
      this.setShow = state;
      //this.getColumnList();
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageNo = 1
      if (this.onScene) {
        this.getDataList(this.dataListQuery)
      } else {
        this.refreshList()
      }
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageNo = val
      if (this.onScene) {
        this.getDataList(this.dataListQuery)
      } else {
        this.refreshList()
      }
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val
    },
    resetSearch() {
      this.searchDates = []
      this.$refs.searchForm.resetFields()
      this.$nextTick(() => {
        this.onScene = false
        this.pageNo = 0
        this.dataListQuery = []
        this.$refs.advancedQuery.addForm.sceneId = ''

        this.refreshList()
      })
    },
    search() {
      this.pageNo = 0
      this.refreshList()
    },
  }
}
</script>
