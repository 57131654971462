<template>
    <el-dialog :title="title" width="60%" :close-on-click-modal="false" :visible.sync="visible">
        <el-form size="small" :model="inputForm" ref="inputForm" label-width="120px" v-loading="loading" :disabled="method==='view'">
            <el-row :gutter="15">
                <el-col :span="12">
                    <el-form-item label="藏品编号" prop="collection_num">
                        <el-input v-model="inputForm.collection_num"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="20">
                    <el-form-item label="藏品名称" prop="collection_name">
                        <el-input v-model="inputForm.collection_name"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="20">
                    <el-form-item label="藏品级别" prop="collection_level">
                        <el-input v-model="inputForm.collection_level"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="20">
                    <el-form-item label="藏品分类" prop="collection_type">
                        <el-input v-model="inputForm.collection_type"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="20">
                    <el-form-item label="藏品年代" prop="collection_years">
                        <el-input v-model="inputForm.collection_years"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="20">
                    <el-form-item label="藏品主质地" prop="collection_texture">
                        <el-input v-model="inputForm.collection_texture"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="20">
                    <el-form-item label="藏品质地类型" prop="collection_texture_type">
                        <el-input v-model="inputForm.collection_texture_type"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="20">
                    <el-form-item label="藏品尺寸" prop="collection_size">
                        <el-input v-model="inputForm.collection_size"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="visible = false">关闭</el-button>
    </span>
    </el-dialog>
</template>

<script>
    export default {
        data() {
            return {
                title: '',
                method: '',
                inputForm: {
                    id: '',
                    collectionNum: "",
                    collectionName: "",
                    collectionLevel: "",
                    collectionType: "",
                    collectionYears: "",
                    collectionTexture: "",
                    collectionTextureType: "",
                    collectionSize: ""
                },
                visible: false,
                loading: false,
            }
        },
        methods: {
            init (method, row) {
                this.method = method
                if (method === 'view') {
                    this.title = '详细信息'
                }
                this.inputForm = row
                this.visible = true
                /*this.$nextTick(() => {
                    this.$refs.inputForm.resetFields();
                    this.inputForm = this.recover(this.inputForm, row)
                })*/
            },
        }
    }
</script>
